import classNames from "classnames";

export const OperationsBanner = ({
  hasBackButton,
  handleClickBackButton,
}: {
  hasBackButton: boolean;
  handleClickBackButton?: () => void;
}): JSX.Element => {
  return (
    <div
      className="flex h-8 items-center bg-violet-500 text-white"
      data-testid="webadmin::navBar"
    >
      <div
        className={classNames("absolute flex h-8 items-center px-3", {
          "cursor-pointer hover:bg-violet-600": hasBackButton,
        })}
      >
        {hasBackButton && (
          <img
            alt=""
            className="rotate-180"
            src="/img/white-arrow-icon.svg"
            onClick={handleClickBackButton}
          />
        )}
      </div>
      <div className="text-xxs w-full text-center">Operations Support Mode</div>
    </div>
  );
};
