import { CommonAppContext } from "@bay1/data";
import { ArrowButton } from "@bay1/ui";
import classNames from "classnames";
import Link from "next/link";
import React, { useCallback, useContext } from "react";

export type EmptyDataStateProps = Readonly<{
  title: string;
  description: string;
  docsButton?: { targetHref: string; text: string };
  createButton?: Readonly<{
    text: string;
    onClick?: () => void;
  }>;
}>;

export const EmptyDataState = ({
  title,
  description,
  createButton,
  docsButton,
}: EmptyDataStateProps): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);

  const hasAnchorTag = docsButton?.targetHref.includes("#") ?? false;

  const handleCreateButtonOnClick = useCallback((): void => {
    if (createButton?.onClick) {
      createButton.onClick();
    }
  }, [createButton]);

  return (
    <div
      className="rounded-highnote border-gray mx-auto my-8 mt-10 w-full max-w-xl overflow-hidden border bg-white p-7 text-left align-middle md:mt-24"
      data-testid="empty-data-state"
    >
      <div className="text-center">
        <h3 className="text-lg leading-6">{title}</h3>
        <div className="mt-2">
          <p className="text-base">{description}</p>
        </div>
      </div>
      <div
        className={classNames("md:mt-5", {
          "sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3":
            docsButton !== undefined && createButton !== undefined,
        })}
      >
        {createButton && (
          <button
            className="btnAccent relative mt-3 inline-flex w-full cursor-pointer text-left"
            onClick={handleCreateButtonOnClick}
            type="button"
          >
            {createButton.text}
            <span className="absolute right-3 mt-0.5">
              {" "}
              <ArrowButton isPrimary={false} />
            </span>
          </button>
        )}

        {docsButton && (
          <Link
            href={
              hasAnchorTag
                ? {
                    pathname: docsButton.targetHref.split("#")[0],
                    hash: docsButton.targetHref.split("#")[1],
                    query: {
                      activeOrganizationId: activeOrganization?.id,
                      anchorTag: docsButton.targetHref.split("#")[1],
                    },
                  }
                : {
                    pathname: docsButton.targetHref,
                    query: {
                      activeOrganizationId: activeOrganization?.id,
                    },
                  }
            }
            className="btnSecondaryActive relative col-start-1 mt-3 inline-flex w-full items-center rounded-full border border-black bg-white px-4 py-2 text-left text-sm duration-200 ease-in"
          >
            {docsButton.text}
            <span className="absolute right-3">
              <ArrowButton isPrimary={false} />
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};
