import { CommonAppContext } from "@bay1/data";
import { SUPPORT_EMAIL } from "@bay1/data/helpers";
import { Footer } from "@bay1/ui";
import Head from "next/head";
import type { PropsWithChildren } from "react";
import { useContext } from "react";

import { DashboardNav } from "./DashboardNav";
import { SidebarCardProducts } from "./SidebarCardProducts";

export const LoginGate = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { loginError } = useContext(CommonAppContext);

  if (loginError) {
    return (
      <>
        <Head>
          <title>Highnote</title>
        </Head>
        <div className="flex h-screen flex-col justify-between">
          <DashboardNav>
            <SidebarCardProducts />
          </DashboardNav>
          <div className="flex bg-white">
            <main
              className="relative flex-1 overflow-y-auto focus:outline-none"
              tabIndex={0}
            >
              <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
                <div
                  className="rounded-highnote bg-red-50 p-4"
                  data-testid="errorBox"
                >
                  <h3
                    className="text-red text-center text-sm"
                    data-testid="errorBox::message"
                  >
                    <p className="mb-1 text-lg">Something went wrong.</p>
                    <p>
                      {"Try again or contact "}
                      <a className="underline" href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                      </a>
                      .
                    </p>
                  </h3>
                </div>
              </div>
            </main>
          </div>
          <Footer />
        </div>
      </>
    );
  }

  return <>{children}</>;
};
