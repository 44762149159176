import React from "react";

export const DetailViewLoader = (): JSX.Element => (
  <div className="grid min-h-0 animate-pulse auto-cols-auto grid-cols-3 items-start gap-5">
    <div className="rounded-highnote col-span-3 w-full bg-white px-4 py-9 sm:px-6 md:px-8">
      <div className="flex items-center space-x-2 pb-3">
        <div className="bg-bone h-5 w-20 rounded" />
      </div>
      <div className="items-center md:flex md:justify-between">
        <div className="items-center space-y-2 md:flex md:space-x-2 md:space-y-0">
          <div className="bg-bone h-10 w-10 rounded-lg md:flex" />
          <div className="bg-bone h-10 w-40 rounded" />
        </div>
        <div className="bg-bone h-8 w-40 rounded" />
      </div>
    </div>
    <div className="rounded-highnote col-span-3 bg-white px-4 pb-10 sm:px-6 md:px-8 lg:col-span-2">
      <div className="border-ash border-b py-5">
        <div className="bg-bone h-10 w-40 rounded" />
      </div>
      <div className="space-y-3 py-5">
        <div className="grid grid-cols-3 gap-4">
          <dt className="col-span-1">
            <div className="bg-bone h-6 w-20 rounded" />
          </dt>
          <dd className="col-span-2">
            <div className="bg-bone h-6 w-40 rounded" />
          </dd>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <dt className="col-span-1">
            <div className="bg-bone h-6 w-20 rounded" />
          </dt>
          <dd className="col-span-2">
            <div className="bg-bone h-6 w-40 rounded" />
          </dd>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <dt className="col-span-1">
            <div className="bg-bone h-6 w-20 rounded" />
          </dt>
          <dd className="col-span-2">
            <div className="bg-bone h-6 w-40 rounded" />
          </dd>
        </div>
      </div>
    </div>
    <div className="rounded-highnote col-span-3 h-40 bg-white px-4 pb-8 sm:px-6 md:px-8 lg:col-span-1">
      <div className="border-ash border-b py-5">
        <div className="bg-bone h-10 w-40 rounded" />
      </div>
    </div>
  </div>
);
