"use client";

import { ArrowButton } from "@bay1/ui";
import type { FormiumClient } from "@formium/client";
import { captureException } from "@sentry/nextjs";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React from "react";
import type { DeepReadonly } from "ts-essentials";

import { emailNewsletterValidationSchema } from "../../../dashboard/validation";

interface NewsletterSignupFormProperties {
  formiumClient: FormiumClient;
  onSuccess: () => Promise<void>;
}

interface NewsletterSignupFormValues {
  email: string;
}

const initialValues: NewsletterSignupFormValues = { email: "" };

const NewsletterSignupForm = ({
  formiumClient,
  onSuccess,
}: Readonly<NewsletterSignupFormProperties>): JSX.Element => {
  const handleSubmit = React.useCallback(
    async (
      values: Readonly<NewsletterSignupFormValues>,
      actions: Readonly<FormikHelpers<NewsletterSignupFormValues>>,
    ) => {
      try {
        await formiumClient.submitForm("newsletter", values);
        actions.setSubmitting(false);
        await onSuccess();
      } catch (error: unknown) {
        captureException(error);
      }
    },
    [formiumClient, onSuccess],
  );

  return (
    <div className="mt-5">
      <h3 className="text-xxs mb-4 font-medium uppercase tracking-widest text-gray-400">
        Stay Updated
      </h3>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={emailNewsletterValidationSchema}
      >
        {({
          isSubmitting,
        }: Readonly<
          FormikProps<Readonly<NewsletterSignupFormValues>>
        >): JSX.Element => (
          <Form className="inline-flex sm:flex-initial">
            <label className="sr-only" htmlFor="email">
              Email
            </label>
            <Field id="email" name="email" type="email">
              {({ field, meta }: DeepReadonly<FieldProps>): JSX.Element => (
                <div className="">
                  <label className="sr-only" htmlFor="email">
                    Enter your email
                  </label>
                  <input
                    className="focus:border-green focus:ring-green w-40 items-center space-x-2 rounded-none rounded-l-full border border-white bg-transparent px-4 py-2 pl-5 text-sm text-white placeholder-gray-200 focus:outline-none md:w-full"
                    placeholder="Email address"
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <div className="bg-red mt-1.5 table rounded p-1 text-xs">
                      {meta.error}
                    </div>
                  )}
                </div>
              )}
            </Field>
            <div className="sm:shrink-0">
              <button
                className="hover:bg-green hover:border-green relative inline-flex items-center rounded-r-full border border-transparent bg-white px-3 py-2 text-sm hover:text-black focus:outline-none"
                disabled={isSubmitting}
                type="submit"
              >
                Subscribe <ArrowButton isPrimary />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <p className="mt-3.5 text-xs leading-5 text-white">
        You&#39;ll receive occasional emails from Highnote. You always have the
        choice to unsubscribe within every email.
      </p>
    </div>
  );
};

export default NewsletterSignupForm;
