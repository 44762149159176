import React from "react";

export const FormGroup = ({
  children,
  title,
  smallTitle,
  description,
}: Readonly<
  React.PropsWithChildren<{
    title?: string;
    smallTitle?: string;
    description?: JSX.Element | string;
  }>
>): JSX.Element => (
  <div className="my-3 px-4 py-3 pb-4 sm:px-8">
    {title && <h2 className="mb-4 text-lg">{title}</h2>}
    {smallTitle && (
      <h2 className="font-display mb-4 text-base">{smallTitle}</h2>
    )}
    {description && <div className="-mt-3 mb-4 text-xs">{description}</div>}
    {children}
  </div>
);
