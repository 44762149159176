import type { ArrayElement } from "@bay1/data";
import type { UserConnection } from "@bay1/sdk/generated/graphql";
import {
  ExternallyInitiatedAchStatus,
  IntegratorInitiatedAchStatus,
  InterFinancialAccountTransferStatus,
  Maybe,
  PaymentCardStatus,
  TransactionEventResponseCode,
  UserRole,
  WireTransferStatus,
} from "@bay1/sdk/generated/graphql";
import Dinero, { Currency } from "dinero.js";
import title from "title";

export type UserNode = ArrayElement<UserConnection["edges"]>["node"] & {
  __typename?: string;
};

export const checkUserAccess = (
  permissions: Readonly<UserRole[]>,
  roles: Maybe<UserRole[]>,
): boolean => {
  // eslint-disable-next-line fp/no-let
  let grantAccess = false;
  permissions.forEach((permission) => {
    if (roles && roles.includes(permission)) {
      // eslint-disable-next-line fp/no-mutation
      grantAccess = true;
    }
  });
  return grantAccess;
};

export const stringToRole = (input: string): UserRole => {
  switch (input) {
    case "ADMIN":
      return UserRole.ADMIN;
    case "DEVELOPER":
      return UserRole.DEVELOPER;
    case "SUPPORT":
      return UserRole.SUPPORT;
    case "FINANCE":
      return UserRole.FINANCE;
    case "USER":
      return UserRole.USER;

    default:
      return UserRole.UNSPECIFIED;
  }
};

export const getUserRole = (roles: Readonly<UserRole[]> | undefined) => {
  if (roles) {
    if (roles.includes(UserRole.ADMIN)) {
      return UserRole.ADMIN;
    }
    if (roles.includes(UserRole.DEVELOPER)) {
      return UserRole.DEVELOPER;
    }
    if (roles.includes(UserRole.FINANCE)) {
      return UserRole.FINANCE;
    }
    if (roles.includes(UserRole.SUPPORT)) {
      return UserRole.SUPPORT;
    }
    if (roles.includes(UserRole.USER)) {
      return UserRole.USER;
    }
  }
  return UserRole.UNSPECIFIED;
};

export enum OpsRoles {
  APP_MGMT_MANAGER = "app_mgmt_manager",
  APP_MGMT_SUPPORT = "app_mgmt_support",
  APP_MGMT_EXTERNAL_AGENCY = "app_mgmt_external_agency",
  IDENTITY_MGMT_MANAGER = "identity_mgmt_manager",
  IDENTITY_MGMT_SUPPORT = "identity_mgmt_support",
  ACCOUNT_MGMT_MANAGER = "account_mgmt_manager",
  ACCOUNT_MGMT_SUPPORT = "account_mgmt_support",
  RISK_MGMT_MANAGER = "risk_mgmt_manager",
  RISK_MGMT_SUPPORT = "risk_mgmt_support",
  DISPUTES_MGMT_MANAGER = "disputes_mgmt_manager",
  DISPUTES_MGMT_SUPPORT = "disputes_mgmt_support",
  ALL = "all",
}

export const getCurrencyDecimals = (currencyCode: string) => {
  const hasZeroDecimals =
    currencyCode === "CVE" ||
    currencyCode === "DJF" ||
    currencyCode === "GNF" ||
    currencyCode === "IDR" ||
    currencyCode === "JPY" ||
    currencyCode === "KMF" ||
    currencyCode === "KRW" ||
    currencyCode === "PYG" ||
    currencyCode === "RWF" ||
    currencyCode === "UGX" ||
    currencyCode === "VND" ||
    currencyCode === "VUV" ||
    currencyCode === "XAF" ||
    currencyCode === "XOF" ||
    currencyCode === "XPF";

  const hasThreeDecimals =
    currencyCode === "BHD" ||
    currencyCode === "IQD" ||
    currencyCode === "JOD" ||
    currencyCode === "KWD" ||
    currencyCode === "LYD" ||
    currencyCode === "OMR" ||
    currencyCode === "TND";

  if (hasZeroDecimals) {
    return 0;
  } else if (hasThreeDecimals) {
    return 3;
  } else {
    return 2;
  }
};

export const formatCurrencyAmount = (
  amount: number,
  currencyCode: Maybe<string>,
  truncateDecimals: boolean = false,
  fourImpliedDecimals: boolean = false,
) => {
  const currencyDecimals = getCurrencyDecimals(currencyCode ?? "USD");

  const currencyAmount = Dinero({
    amount: amount,
    currency: (currencyCode ?? "USD") as Currency,
    precision: fourImpliedDecimals ? currencyDecimals + 2 : currencyDecimals,
  });

  if (truncateDecimals) {
    return currencyAmount.toFormat("$0,0");
  }

  switch (currencyDecimals) {
    case 0: {
      return currencyAmount.toFormat("$0,0");
    }
    case 3: {
      return currencyAmount.toFormat("$0,0.000");
    }
    default: {
      return currencyAmount.toFormat("$0,0.00");
    }
  }
};

export const formatPaymentCardStatus = (status?: PaymentCardStatus): string => {
  switch (status) {
    case PaymentCardStatus.ACTIVATION_REQUIRED:
      return "Activation Required";
    case PaymentCardStatus.ACTIVE:
      return "Active";
    case PaymentCardStatus.SUSPENDED:
      return "Suspended";
    case PaymentCardStatus.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatIntegratorInitiatedAchStatusCode = (
  status: Maybe<IntegratorInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatExternallyInitiatedAchStatusCode = (
  status: Maybe<ExternallyInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatWireTransferStatus = (
  status: Maybe<WireTransferStatus>,
): string => (status ? title(status) : "");

export const formatInterFinancialAccountTransferStatus = (
  status: Maybe<InterFinancialAccountTransferStatus>,
): string => (status ? title(status) : "");

export const formatTransactionEventResponseCode = (
  responseCode: Maybe<TransactionEventResponseCode>,
): string => {
  if (!responseCode) {
    return "";
  }

  switch (responseCode) {
    case TransactionEventResponseCode.APPROVED_FOR_PARTIAL_AMOUNT:
      return "Partially Approved";
    case TransactionEventResponseCode.APPROVED_FOR_PURCHASE_AMOUNT_ONLY:
      return "Approved - Purchase Only";
    case TransactionEventResponseCode.BAD_CVV:
      return "Bad CVV";
    case TransactionEventResponseCode.BAD_CVV2:
      return "Bad CVV2";
    case TransactionEventResponseCode.BAD_CVC3:
      return "Bad CVC3";
    case TransactionEventResponseCode.INVALID_AUTHORIZATION_EXPIRATION:
      return "Invalid Authorization";
    case TransactionEventResponseCode.RE_ENTER_TRANSACTION:
      return "Re-enter Transaction";
    case TransactionEventResponseCode.CA_DECLINED:
      return "Declined by Collab Auth";

    default:
      return title(responseCode.replaceAll("_", " "));
  }
};

export const formatTransactionEventResponseCodeToApprovedOrDeclined = (
  responseCode: Maybe<TransactionEventResponseCode>,
): string => {
  if (!responseCode) {
    return "";
  }

  switch (responseCode) {
    case TransactionEventResponseCode.APPROVED_FOR_PARTIAL_AMOUNT:
    case TransactionEventResponseCode.APPROVED_FOR_PURCHASE_AMOUNT_ONLY:
    case TransactionEventResponseCode.APPROVED:
      return "Approved";

    default:
      return "Declined";
  }
};
