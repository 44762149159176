import { CommonAppContext } from "@bay1/data";
import type { CardProduct, Maybe } from "@bay1/sdk/generated/graphql";
import { UserRole } from "@bay1/sdk/generated/graphql";
import { ErrorBox, TestOnly } from "@bay1/ui";
import { checkUserAccess } from "@bay1/ui/helpers";
import classNames from "classnames";
import { NextRouter, useRouter } from "next/router";
import React, {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCardProducts } from "../hooks/useCardProducts";
import { SidebarCardProduct } from "./SidebarCardProduct";

const SidebarCardProductsRoot = ({
  children,
  disabled,
  cardProducts,
  router,
  organizationId,
}: Readonly<
  PropsWithChildren<{
    disabled: boolean;
    cardProducts: Maybe<CardProduct>[] | undefined;
    router?: NextRouter;
    organizationId?: Maybe<string>;
  }>
>): JSX.Element => {
  const handleClick = useCallback(
    (clickEvent: SyntheticEvent<HTMLDivElement>): void => {
      clickEvent.preventDefault();

      void router?.push(`/organizations/${organizationId}/create-card-product`);
    },
    [organizationId, router],
  );

  const { user } = useContext(CommonAppContext);

  return (
    <div
      className="space-y-3"
      data-testid={disabled ? "sidebar::cardProduct:disabled" : ""}
    >
      <div className="space-y-1.5" role="group">
        {children}
        {cardProducts &&
          cardProducts.length > 0 &&
          !checkUserAccess([UserRole.SUPPORT], user?.roles) && (
            <TestOnly>
              <div className="px-0 md:px-2">
                <hr className="border-ash mx-2 mt-2 border-t" />
                <div
                  className={classNames(
                    "rounded-rounded hover:bg-ash mt-2 flex w-full items-center truncate px-2 py-1",
                    {
                      "cursor-pointer": !disabled,
                      "cursor-not-allowed": disabled,
                    },
                  )}
                  data-testid="button::create-card-product"
                  onClick={handleClick}
                >
                  <img alt="" src="/img/nav-plus.svg" className="pl-1.5" />
                  <span
                    className={classNames(
                      "ml-0.5 cursor-pointer truncate pl-4",
                      {
                        "cursor-not-allowed": disabled,
                      },
                    )}
                  >
                    Add New Product
                  </span>
                </div>
              </div>
            </TestOnly>
          )}
      </div>
    </div>
  );
};

export const SidebarCardProducts = (): JSX.Element => {
  const router = useRouter();
  const { id: organizationIds, cardProductId: cardProductIds } = router.query;

  const [organizationId] = Array(organizationIds).flat();
  const [cardProductId] = Array(cardProductIds).flat();

  const {
    cardProducts,
    error: cardProductsError,
    loading,
    cardProductPageInfo,
    fetchMore,
  } = useCardProducts();

  const handleFetchMoreCardProducts = useCallback(() => {
    fetchMore({
      variables: {
        after: cardProductPageInfo?.endCursor,
      },
    });
  }, [cardProductPageInfo?.endCursor, fetchMore]);

  const [currentCardProductIndex, setCurrentCardProductIndex] =
    useState<number>(
      cardProducts.findIndex(
        (cardProduct) => cardProduct?.id === cardProductId,
      ),
    );

  const [showAllProducts, setShowAllProducts] = useState(
    cardProducts.findIndex((cardProduct) => cardProduct?.id === cardProductId) >
      2
      ? true
      : false,
  );

  const handleClick = useCallback(
    (clickEvent: SyntheticEvent<HTMLAnchorElement> | boolean): void => {
      if (typeof clickEvent === "object" && "preventDefault" in clickEvent) {
        clickEvent.preventDefault();
      }

      void router.push(`/organizations/${organizationId}/create-card-product`);
    },
    [organizationId, router],
  );

  const handleSetShowAllProducts = useCallback(() => {
    setShowAllProducts(!showAllProducts);
  }, [showAllProducts]);

  useEffect(() => {
    if (cardProducts.length > 0) {
      setCurrentCardProductIndex(
        cardProducts.findIndex(
          (cardProduct) => cardProduct?.id === cardProductId,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardProducts, cardProductId]);

  useEffect(() => {
    if (currentCardProductIndex > 2) {
      setShowAllProducts(true);
    }
  }, [currentCardProductIndex]);

  if (loading) {
    return (
      <SidebarCardProductsRoot cardProducts={cardProducts} disabled>
        <div className="animate-pulse space-y-1.5 px-4">
          <div>
            <div className="bg-ash inline-block h-5 w-36 rounded" />
          </div>
          <div>
            <div className="bg-ash inline-block h-5 w-28 rounded" />
          </div>
        </div>
      </SidebarCardProductsRoot>
    );
  }

  if (cardProductsError) {
    return (
      <SidebarCardProductsRoot cardProducts={cardProducts} disabled>
        <div className="mt-6">
          <ErrorBox message={cardProductsError.message} />
        </div>
      </SidebarCardProductsRoot>
    );
  }

  return (
    <>
      <SidebarCardProductsRoot
        cardProducts={cardProducts}
        disabled={false}
        organizationId={organizationId}
        router={router}
      >
        {cardProducts?.length ?? 0 ? (
          cardProducts?.map((cardProduct, index) => {
            if (index > 2 && !showAllProducts) {
              return <></>;
            }
            return (
              cardProduct && (
                <SidebarCardProduct
                  cardProduct={cardProduct}
                  key={cardProduct?.id}
                  organizationHasSingleProduct={cardProducts.length === 1}
                />
              )
            );
          })
        ) : (
          <TestOnly>
            <div className="px-0 md:px-2">
              <a
                className="text-md rounded-rounded hover:bg-ash group flex grow-0 items-center px-2 py-1.5"
                href="#"
                onClick={handleClick}
              >
                <span
                  aria-hidden="true"
                  className="relative mr-3 flex h-5 w-5 shrink-0 items-center justify-center"
                >
                  <span className="absolute h-4 w-4 animate-ping rounded-full bg-green-200" />
                  <span className="bg-green relative block h-2 w-2 rounded-full duration-300 ease-in-out" />
                </span>
                Create First Product
              </a>
            </div>
          </TestOnly>
        )}
        {cardProducts.length > 3 && (
          <div className="px-0 md:px-2">
            {showAllProducts && (
              <button
                onClick={handleFetchMoreCardProducts}
                disabled={!cardProductPageInfo?.hasNextPage}
                type="button"
                className={classNames(
                  "rounded-rounded hover:bg-ash flex w-full items-center truncate px-2 py-1",
                  {
                    "cursor-not-allowed text-gray-400":
                      !cardProductPageInfo?.hasNextPage,
                  },
                )}
              >
                {cardProductPageInfo?.hasNextPage ? (
                  <img
                    alt=""
                    src="/img/nav-plus.svg"
                    className={classNames("pl-1.5", {
                      "animate-pulse": loading,
                    })}
                  />
                ) : (
                  <img
                    alt=""
                    src="/img/nav-plus.svg"
                    className="pl-1.5 opacity-20"
                  />
                )}
                <span className="ml-0.5 pl-4">
                  {cardProductPageInfo?.hasNextPage
                    ? "Load More"
                    : "No more items"}
                </span>
              </button>
            )}
            <button
              onClick={handleSetShowAllProducts}
              disabled={
                currentCardProductIndex
                  ? currentCardProductIndex > 2
                    ? true
                    : false
                  : false
              }
              type="button"
              className={classNames(
                "rounded-rounded hover:bg-ash flex w-full items-center truncate px-2 py-1",
                {
                  "cursor-not-allowed": currentCardProductIndex
                    ? currentCardProductIndex > 2
                      ? true
                      : false
                    : false,
                },
              )}
            >
              {!showAllProducts ? (
                <img
                  alt=""
                  src="/img/nav-dropdown.svg"
                  className="rotate-180 pr-1.5 opacity-20"
                />
              ) : (
                <img
                  alt=""
                  src="/img/nav-dropdown.svg"
                  className="pl-1.5 opacity-100"
                />
              )}
              <span className="ml-0.5 pl-4">
                {!showAllProducts ? "View All" : "View Less"}
              </span>
            </button>
          </div>
        )}
      </SidebarCardProductsRoot>
    </>
  );
};
