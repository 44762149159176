import { TransactionEventResponseCode } from "@bay1/sdk/generated/graphql";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

import {
  formatTransactionEventResponseCode,
  formatTransactionEventResponseCodeToApprovedOrDeclined,
} from "../helpers";

export const TransactionEventResponseCodePill = ({
  responseCode,
}: Readonly<{
  responseCode?: TransactionEventResponseCode;
}>): JSX.Element => {
  const [showReason, setShowReason] = useState(false);
  const handleShowReason = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setShowReason(!showReason);
    },
    [showReason],
  );

  const handleCloseReason = useCallback(() => {
    setShowReason(false);
  }, []);

  if (!responseCode) {
    return (
      <div className="flex w-max rounded-full bg-red-100 px-1 py-0.5 pr-3 text-xs">
        <div className="flex items-center space-x-1.5">
          <img alt="" src="/img/error-icon.svg" />
          <span>Unknown Event</span>
        </div>
      </div>
    );
  }

  const approved =
    responseCode === TransactionEventResponseCode.APPROVED ||
    responseCode ===
      TransactionEventResponseCode.APPROVED_FOR_PURCHASE_AMOUNT_ONLY ||
    responseCode === TransactionEventResponseCode.APPROVED_FOR_PARTIAL_AMOUNT;

  return (
    <>
      <div
        className={classNames(
          "relative inline-flex w-max cursor-pointer items-center rounded-full px-1 py-0.5 pr-3 text-xs text-black",
          {
            "bg-green-100": approved,

            "bg-red-100": !approved,
          },
          {},
        )}
        onMouseEnter={handleShowReason}
        onMouseLeave={handleCloseReason}
      >
        <div className="flex items-center space-x-1.5">
          {approved ? (
            <img alt="" src="/img/approved-icon.svg" />
          ) : (
            <img alt="" src="/img/decline-icon.svg" />
          )}
          <span>
            {formatTransactionEventResponseCodeToApprovedOrDeclined(
              responseCode,
            )}
          </span>
        </div>
        {showReason && (
          <div className="absolute -top-14 z-10 -ml-10">
            <div className="border-ash -ml-10 -mt-2 w-64 rounded-lg border bg-white p-2 text-center">
              {approved ? (
                <>
                  Transaction was
                  <div className="font-medium">
                    {formatTransactionEventResponseCode(responseCode)}
                  </div>
                </>
              ) : (
                <>
                  Transaction declined due to
                  <div className="font-medium">
                    {formatTransactionEventResponseCode(responseCode)}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
