import { useQuery } from "@apollo/client";
import {
  ListCardProductsDocument,
  ListCardProductsQuery,
  ListCardProductsQueryVariables,
} from "@bay1/sdk/generated/graphql";

export function useCardProducts() {
  const { data: cardProductsData, ...query } = useQuery<
    ListCardProductsQuery,
    ListCardProductsQueryVariables
  >(ListCardProductsDocument, {
    variables: {
      first: 20,
    },
  });

  const cardProducts = cardProductsData?.cardProducts?.edges?.map((edge) => {
    if (edge.node?.__typename === "CardProduct") return edge.node;
  });

  const cardProductPageInfo = cardProductsData?.cardProducts?.pageInfo;

  return {
    cardProducts: cardProducts ?? [],
    cardProductPageInfo,
    ...query,
  };
}
