import { USStatesAndTerritories } from "@bay1/data";
import { Iso3166Alpha3Country, PhoneLabel } from "@bay1/sdk/generated/graphql";
import { array, boolean, number, object, string } from "yup";

export const MIN_STRING_DEFAULT = 2;

const MAX_STRING_DEFAULT = 50;

export const MAX_SUFFIX_OR_TITLE = 10;
export const MAX_MIDDLE_NAME = 50;

export const FORMATTED_PHONE_NUMBER = 12;
export const MAX_PHONE_EXTENSION = 6;

const ROUTING_NUMBER_LENGTH = 9;
const MIN_ACCOUNT_NUMBER = 5;
const MAX_ACCOUNT_NUMBER = 17;

// https://owasp.org/www-community/OWASP_Validation_Regex_Repository
export const US_POSTAL_CODE_REGEX = new RegExp(/^\d{5}(-\d{4})?$/, "u");
export const MIN_US_POSTAL_CODE = 5;
export const MAX_US_POSTAL_CODE = 10;

export const COUNTRY_CODE_LENGTH = 3;
export const US_STATE_CODE_LENGTH = 2;

export const MIN_STREET_ADDRESS = 3;
export const MAX_STREET_ADDRESS = 255;

export const MIN_LOCALITY = 2;
export const MAX_LOCALITY = 255;

const MONTH_LENGTH = 2;
const DAY_LENGTH = 2;
const NUM_MONTHS = 12;

export const MAX_EXTERNAL_ID = 255;

export const EIN_LENGTH = 10;
export const MAX_WEBSITE_LENGTH = 255;

const MAX_ENDPOINT_NAME_LENGTH = 255;

export const MAX_AMOUNT_VALUE = (Number.MAX_SAFE_INTEGER + 1) / 128 - 1;
export const MIN_AMOUNT_VALUE = 0.01;

// https://owasp.org/www-community/OWASP_Validation_Regex_Repository
export const US_SSN_REGEX = new RegExp(/^\d{3}-\d{2}-\d{4}$/, "u");

export const MAX_PERCENTAGE_OWNERSHIP = 100;
export const MIN_BENEFICIAL_OWNER_PERCENTAGE_OWNERSHIP = 25;

const currentYear = new Date().getFullYear();

// TODO: Look at superstruct instead
export const minMaxString = (
  min = MIN_STRING_DEFAULT,
  max = MAX_STRING_DEFAULT,
) =>
  string()
    .min(min, `Must be at least ${min} characters`)
    .max(max, `Maximum length: ${max}`);

export const nameSchema = object()
  .required()
  .shape({
    familyName: minMaxString().required("Required"),
    givenName: minMaxString().required("Required"),
    middleName: minMaxString(1, MAX_MIDDLE_NAME).notRequired(),

    suffix: minMaxString(MIN_STRING_DEFAULT, MAX_SUFFIX_OR_TITLE).notRequired(),

    title: minMaxString(MIN_STRING_DEFAULT, MAX_SUFFIX_OR_TITLE).notRequired(),
  });

export const countryCodeAlpha3 = string()
  .length(COUNTRY_CODE_LENGTH, "Must be a valid Country Code.")
  .default(Iso3166Alpha3Country.USA);

export const addressSchema = object()
  .required()
  .shape({
    countryCodeAlpha3: countryCodeAlpha3.required("Required"),

    extendedAddress: minMaxString(1, MAX_STREET_ADDRESS).notRequired(),

    locality: minMaxString(MIN_LOCALITY, MAX_LOCALITY).required("Required"),

    // Note: This is scoped to US only
    postalCode: minMaxString(MIN_US_POSTAL_CODE, MAX_US_POSTAL_CODE)
      .required("Required")
      .matches(US_POSTAL_CODE_REGEX, "Must be a valid US zip code."),

    // This is scoped to US only
    region: string()
      .required()
      .length(US_STATE_CODE_LENGTH, "Must be 2-letter state code")
      .oneOf(
        Object.values(USStatesAndTerritories),
        "Must be a valid US State or Territory.",
      ),

    streetAddress: minMaxString(
      MIN_STREET_ADDRESS,
      MAX_STREET_ADDRESS,
    ).required("Required"),
  });

export const monthSchema = string()
  .length(MONTH_LENGTH, "Invalid Month")
  .required("Required")
  .test("dateOfBirthMonth", "Invalid Month", (value, { createError }) => {
    const valueAsNumber = Number(value);

    if (
      !Number.isNaN(valueAsNumber) &&
      valueAsNumber >= 1 &&
      valueAsNumber <= NUM_MONTHS
    ) {
      return true;
    }

    return createError({ message: "Invalid Month" });
  });

export const daySchema = string()
  .length(DAY_LENGTH, "Invalid Day")
  .required("Required")
  .test("dateOfBirthDay", "Invalid Day", (value, { createError }) => {
    const valueAsNumber = Number(value);

    if (
      !Number.isNaN(valueAsNumber) &&
      valueAsNumber >= 1 &&
      // TODO: This needs to be contextual
      valueAsNumber <= 31
    ) {
      return true;
    }

    return createError({ message: "Invalid Day" });
  });

export const yearSchema = string()
  .length(4, "Invalid Year")
  .required("Required");

export const dateOfBirthYearSchema = (
  minYearsOfAge = 18,
  maxYearsOfAge = 125,
) =>
  yearSchema.test(
    "dateOfBirthYear",
    "Invalid Year",
    (value, { createError }) => {
      const valueAsNumber = Number(value);

      if (
        !Number.isNaN(valueAsNumber) &&
        valueAsNumber >= currentYear - maxYearsOfAge &&
        valueAsNumber <= currentYear - minYearsOfAge
      ) {
        return true;
      }

      return createError({ message: "Invalid Year" });
    },
  );

export const dateOfBirthSchema = object().required().shape({
  month: monthSchema,
  day: daySchema,

  year: dateOfBirthYearSchema(),
});

export const phoneNumberSchema = object()
  .required()
  .shape({
    countryCode: minMaxString(1, COUNTRY_CODE_LENGTH).required().default("1"),

    number: string()
      .length(FORMATTED_PHONE_NUMBER, "A valid phone number is required")
      .required("Required"),

    extension: string().max(MAX_PHONE_EXTENSION).notRequired(),

    label: string().oneOf(Object.values(PhoneLabel)).default(PhoneLabel.HOME),
  });

export const createAccountHolderValidationSchema = object()
  .required()
  .shape({
    name: nameSchema,

    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,

    dateOfBirth: dateOfBirthSchema,

    externalId: string().notRequired().max(MAX_EXTERNAL_ID),

    identificationDocument: object()
      .required()
      .shape({
        socialSecurityNumber: object()
          .required()
          .shape({
            countryCodeAlpha3: string()
              .required("Required")
              .length(COUNTRY_CODE_LENGTH)
              .default("USA"),

            number: string()
              .required("A valid US Social Security Number is required.")
              .matches(
                US_SSN_REGEX,
                "A valid US Social Security Number is required.",
              ),
          }),
      }),
  });

export const updateAccountHolderValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,
  });

export const updateAssociatedPersonAccountHolderValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    homeAddress: addressSchema,
  });

export const updateBusinessAccountHolderValidationSchema = object()
  .required()
  .shape({
    website: string().max(MAX_WEBSITE_LENGTH),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,
  });

export const updateApplicationBusinessAccountHolderInformationValidationSchema =
  object()
    .required()
    .shape({
      updateIdentity: boolean().required(),
      updateDetails: object()
        .required()
        .shape({
          phoneNumber: phoneNumberSchema,
          billingAddress: addressSchema,
          identificationDocument: object().shape({
            employerIdentificationNumber: object().shape({
              countryCodeAlpha3: string()
                .required("Required")
                .length(COUNTRY_CODE_LENGTH)
                .default("USA"),

              number: string()
                .required("Required")
                .length(EIN_LENGTH, "A valid EIN is required"),
            }),
          }),
          name: object()
            .required()
            .shape({
              legalBusinessName: minMaxString().required("Required"),
              doingBusinessAsName: minMaxString(),
            }),
        }),
    });

export const updateApplicationAssociatedPersonAccountHolderInformationValidationSchema =
  (isPrimaryAuthorizedPerson: boolean) =>
    object()
      .required()
      .shape({
        updateIdentity: boolean().required(),
        updateDetails: object()
          .required()
          .shape({
            dateOfBirth: dateOfBirthSchema,
            email: string()
              .email("A valid email address is required")
              .required("Required"),
            homeAddress: addressSchema,
            identificationDocument: object()
              .required()
              .shape({
                socialSecurityNumber: object()
                  .required()
                  .shape({
                    countryCodeAlpha3: string()
                      .required("Required")
                      .length(COUNTRY_CODE_LENGTH)
                      .default("USA"),

                    number: string()
                      .required(
                        "A valid US Social Security Number is required.",
                      )
                      .matches(
                        US_SSN_REGEX,
                        "A valid US Social Security Number is required.",
                      ),
                  }),
              }),
            name: nameSchema,
            percentageOwnership: isPrimaryAuthorizedPerson
              ? number()
                  .typeError("Invalid percentage.")
                  .required("Required")
                  .integer()
                  .min(0, "Must be greater than or equal to ${min}")
                  .max(
                    MAX_PERCENTAGE_OWNERSHIP,
                    "Must be less than or equal to ${max}",
                  )
              : number()
                  .typeError("Invalid percentage.")
                  .required("Required")
                  .integer()
                  .min(MIN_BENEFICIAL_OWNER_PERCENTAGE_OWNERSHIP)
                  .max(MAX_PERCENTAGE_OWNERSHIP),

            phoneNumber: phoneNumberSchema,
          }),
      });

export const updateApplicationPersonAccountHolderInformationValidationSchema =
  () =>
    object()
      .required()
      .shape({
        updateIdentity: boolean().required(),
        updateDetails: object()
          .required()
          .shape({
            dateOfBirth: dateOfBirthSchema,
            email: string()
              .email("A valid email address is required")
              .required("Required"),
            billingAddress: addressSchema,
            identificationDocument: object()
              .required()
              .shape({
                socialSecurityNumber: object()
                  .required()
                  .shape({
                    countryCodeAlpha3: string()
                      .required("Required")
                      .length(COUNTRY_CODE_LENGTH)
                      .default("USA"),

                    number: string()
                      .required(
                        "A valid US Social Security Number is required.",
                      )
                      .matches(
                        US_SSN_REGEX,
                        "A valid US Social Security Number is required.",
                      ),
                  }),
              }),
            name: nameSchema,

            phoneNumber: phoneNumberSchema,
          }),
      });

export const emailNewsletterValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
  });

export const emailValidationSchema = string()
  .email("Please enter a valid email address")
  .required("Please enter a valid email address");

export const categorySchema = object().shape({
  line1: string().notRequired(),
  line2: string().notRequired(),
  line3: string().notRequired(),
  line4: string().notRequired(),
  line5: string().notRequired(),
  line6: string().notRequired(),
  line7: string().notRequired(),
  line8: string().notRequired(),
  line9: string().notRequired(),
  line10: string().notRequired(),
});

export const reissuePaymentCardSchema = object().shape({
  originalPaymentCardId: string().required(),

  options: object().shape({
    activateOnCreate: boolean().required(),

    expirationDate: object().shape({
      month: string().required(),
      year: string().required(),
    }),

    reissueFeatures: object().shape({
      copyPin: boolean().required(),
      copyNumber: boolean().required(),
    }),
  }),
});

export const addWebhookNotificationTargetSchema = object().shape({
  name: string().required("Required"),
  uri: string().url("Must be a valid URI").required("Required"),

  subscriptions: array()
    .of(
      object().shape({
        category: string(),

        subscriptions: array().of(
          object().shape({
            value: boolean(),
            type: string(),
          }),
        ),
      }),
    )
    .test("subscriptions", (value, { createError }) => {
      const notifications = value?.flatMap((notificationSubscription) =>
        notificationSubscription.subscriptions
          ?.filter((subscription) => subscription.value)
          .map((subscription) => subscription.type),
      );
      if (notifications && notifications.length > 0) {
        return true;
      }
      return createError({ message: "Please Select at least one event" });
    }),
});

export const renameNotificationTargetSchema = object().shape({
  name: string().required(),
});

export const issueFinancialAccountSchema = object().shape({
  name: string().required("Required"),
  applicationId: string().required("Required"),
  externalId: string().notRequired().max(MAX_EXTERNAL_ID),
});

export const addNonVerifiedExternalUSFinancialBankAccountSchema =
  object().shape({
    accountNumber: minMaxString(
      MIN_ACCOUNT_NUMBER,
      MAX_ACCOUNT_NUMBER,
    ).required("Required"),

    bankAccountType: string().required("Required"),
    accountHolderId: string().required(),

    name: string(),

    routingNumber: string()
      .required("Required")
      .length(ROUTING_NUMBER_LENGTH, "Must be 9 characters"),
  });

export const addExternalBankAccountFromTokenSchema = object().shape({
  accountHolderId: string().required(),
  provider: string().required("Required"),
});

export const addCollaborativeAuthorizationEndpointSchema = object().shape({
  name: minMaxString(1, MAX_ENDPOINT_NAME_LENGTH).required("Required"),
  uri: string().url("Must be a valid URI").required("Required"),
});

export const simulateCardDigitalWalletTokenSchema = object().shape({
  paymentCardId: string().required("Required"),
});

export const removeHoldStatusValidationSchema = object().shape({
  transactionId: string().required("Required"),
  override: boolean().required(),
});

export const enableOnDemandFundingValidationSchema = object().shape({
  cardProductId: string().required("Required"),
  pseudoBalanceEnabled: boolean().required(),
});

export const createAuthorizedUserCardProductApplicationValidationSchema =
  object().shape({
    accountHolderCardProductApplicationId: string().required("Required"),
    accountHolderId: string().required("Required"),
    authorizedUserId: string().required(),
    financialAccountId: string().required("Required"),
  });

export const issuePaymentCardForAuthorizedUserSchema = object().shape({
  applicationId: string().required(),

  options: object().shape({
    activateOnCreate: boolean().required(),

    expirationDate: object().shape({
      month: string().required(),
      year: string().required(),
    }),
  }),
});

export const updateAuthorizedUserValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,
  });
