import { useContext, useEffect, useState } from "react";

import {
  ConfirmModalContext,
  ConfirmModalValues,
} from "./ConfirmModalContextProvider";

export const useConfirm = () => {
  const { confirm, setConfirm } = useContext(ConfirmModalContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (prompt: ConfirmModalValues) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({ prompt, isOpen: true, proceed: resolve, cancel: reject });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({
        prompt: { title: "", description: "", buttonText: "" },
        proceed: null,
        cancel: null,
        isOpen: false,
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      },
    );
  };

  useEffect(() => {
    return () => {
      if (confirm?.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};
