import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import { sanitizeUrl } from "@braintree/sanitize-url";

function getRefreshAuthUri(href: string) {
  return `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
    sanitizeUrl(href),
  )}`;
}

export { getRefreshAuthUri };
