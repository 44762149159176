"use client";

import { CommonAppContext } from "@bay1/data";
import type { Maybe } from "@bay1/sdk/generated/graphql";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import Link from "next/link";
import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
} from "react";
import React, { useCallback, useContext, useEffect } from "react";

export const preventAccidentalSlideOverDismissal = (
  event: React.KeyboardEvent<HTMLInputElement>,
): void => {
  if (event.key === "Escape") {
    event.stopPropagation();
  }
};

export interface SlideOverHeaderProps {
  title: string;
  titleTestId?: string;
  subtitle?: string;
  link?: {
    href: string;
    text: string;
  };
}

const SlideOverHeader = ({
  title,
  titleTestId,
  subtitle,
  link,
  children,
}: Readonly<PropsWithChildren<SlideOverHeaderProps>>): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);

  const hasAnchorTag = link?.href.includes("#") ?? false;
  return (
    <div className="pb-5" data-testid={(titleTestId ?? "") && titleTestId}>
      <div className="border-ash relative border-b px-4 pb-8 sm:px-8">
        <h2
          className="headline font-display pb-2 pr-6 text-xl"
          id="slide-over-heading"
        >
          {title}
        </h2>
        <p className="pr-6 text-xs">{subtitle}</p>

        {link && (
          <Link
            href={
              hasAnchorTag
                ? {
                    pathname: link.href.split("#")[0],

                    hash: link.href.split("#")[1],

                    query: {
                      activeOrganizationId: activeOrganization?.id,
                      anchorTag: link.href.split("#")[1],
                    },
                  }
                : {
                    pathname: link.href,

                    query: {
                      activeOrganizationId: activeOrganization?.id,
                    },
                  }
            }
            passHref
            className="group inline-flex items-center pt-3 underline hover:text-gray-500 sm:text-xs"
            data-testid="slide-over::header::documentation-link"
            rel="noreferrer"
            target="_blank"
          >
            <span className="text-xs">{link.text}</span>
          </Link>
        )}
      </div>
      {children}
    </div>
  );
};

export const SlideOver = ({
  isOpen,
  setIsOpen,
  header,
  children,
  afterLeave,
  isWide,
}: Readonly<
  PropsWithChildren<{
    isOpen: boolean;
    header?: SlideOverHeaderProps;
    setIsOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);

    // Some info!
    afterLeave?: () => void;
    isWide?: Maybe<boolean>;
  }>
>): JSX.Element => {
  // TODO: This won't work well with child components who need to "lock UI" for things like requests in flight

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const closeSlideOver = useCallback(
    (clickEvent: SyntheticEvent<HTMLButtonElement | HTMLDivElement>) => {
      if ("preventDefault" in clickEvent) {
        clickEvent.preventDefault();
      }

      setIsOpen(false);
    },
    [setIsOpen],
  );

  useEffect(() => {
    document.body.addEventListener("keyup", handleKeyUp);

    return (): void => {
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const handleAfterLeave = useCallback((): void => {
    afterLeave?.();
  }, [afterLeave]);

  return (
    <Transition
      afterLeave={handleAfterLeave}
      className="absolute z-50"
      show={isOpen}
    >
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            className="fixed inset-0"
            enter="ease-in-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-150 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              aria-hidden="true"
              className="bg-ash/80 absolute inset-0"
              onClick={closeSlideOver}
            />
          </Transition.Child>
          <section
            aria-labelledby="slide-over-heading"
            className="absolute inset-y-0 right-0 flex max-w-full"
          >
            <Transition.Child
              enter="transition ease-in-out duration-75 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-75 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames("relative h-full w-screen p-4", {
                  "max-w-2xl": isWide,
                  "max-w-lg": !(isWide ?? false),
                })}
              >
                <div className="rounded-highnote relative flex h-full flex-col overflow-y-scroll bg-white py-6">
                  <>
                    <div className="absolute right-0 top-0 z-10 flex pr-2 pt-6 sm:-ml-4 sm:pr-4">
                      <button
                        className="hover:bg-bone focus:bg-ash flex h-10 w-10 items-center justify-center rounded-full"
                        data-testid="slide-over::close-panel"
                        onClick={closeSlideOver}
                        type="button"
                      >
                        <span className="sr-only">Close panel</span>
                        {/* Heroicon name: outline/x */}
                        <svg
                          aria-hidden="true"
                          className="h-6 w-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                        </svg>
                      </button>
                    </div>
                    {header ? (
                      <SlideOverHeader {...header}>{children}</SlideOverHeader>
                    ) : (
                      { children }
                    )}
                  </>
                </div>
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};
