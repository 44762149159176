import { useFormikContext } from "formik";
import React from "react";

export const ReportDirty = ({
  onDirty,
}: Readonly<{
  onDirty: (isDirty: boolean) => void;
}>): JSX.Element => {
  const { dirty } = useFormikContext();

  React.useEffect(() => {
    onDirty(dirty);
  }, [dirty, onDirty]);

  return <></>;
};
