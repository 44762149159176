import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import { captureException } from "@sentry/nextjs";

import {
  GraphqlCollection,
  GraphqlOperation,
  OrganizationWithJWT,
} from "./types";

export class CollectionsAPI {
  organization: OrganizationWithJWT | void;

  constructor(organization?: OrganizationWithJWT) {
    // eslint-disable-next-line fp/no-mutation
    this.organization = organization;
  }

  get _headers() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.organization?.jwt}`,
    };
  }

  _getUrl(path: string) {
    return `${
      this.organization?.profile.environment === IntegrationEnvironment.TEST
        ? process.env.NEXT_PUBLIC_TEST_API_URL
        : process.env.NEXT_PUBLIC_API_URL
    }/gqlReferenceOperations${path}`;
  }

  async _makeFetch<T>(path: string, options: RequestInit) {
    const response = await fetch(this._getUrl(path), options);
    const responseBody = await response.json();

    if (responseBody.error) {
      throw Error(responseBody.error);
    }

    return responseBody?.data as T;
  }

  async getCollections() {
    try {
      const response = await this._makeFetch<GraphqlCollection[]>("", {
        method: "GET",
        headers: this._headers,
      });

      return response;
    } catch (error) {
      captureException(error);
      return [];
    }
  }

  async getDocumentsByParent({
    collectionId,
    parentId,
  }: {
    collectionId: string;
    parentId: string;
  }) {
    try {
      const response = await this._makeFetch<GraphqlOperation[]>(
        `/${collectionId}/${parentId}`,
        {
          method: "GET",
          headers: this._headers,
        },
      );

      return response;
    } catch (error) {
      captureException(error);
      return [];
    }
  }

  async createCollection({
    collectionId,
    collectionName,
  }: {
    collectionId: string;
    collectionName: string;
  }) {
    try {
      const response = await this._makeFetch<GraphqlCollection>(
        `/${collectionId}`,
        {
          method: "POST",
          headers: this._headers,
          body: JSON.stringify({
            collectionName,
          }),
        },
      );

      return response;
    } catch (error) {
      captureException(error);
      throw error;
    }
  }

  async updateChildInCollection({
    collectionId,
    parentId,
    childId,
    body,
  }: {
    collectionId: string;
    parentId: string;
    childId: string;
    body: GraphqlOperation;
  }) {
    try {
      await this._makeFetch(`/${collectionId}/${parentId}/${childId}`, {
        method: "PUT",
        headers: this._headers,
        body: JSON.stringify(body),
      });

      return body;
    } catch (error) {
      captureException(error);
      throw error;
    }
  }
}
