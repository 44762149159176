import React from "react";

import {
  TableHeaderAlignLeft,
  TableHeaderAlignRight,
  TableHeaderAlignRightByN,
} from ".";

export const Table = (
  props: Readonly<
    React.PropsWithChildren<{
      columns: ReadonlyArray<string>;
      alignRight?: boolean;
      alignRightByN?: number;
    }>
  >,
): JSX.Element => {
  const { children, columns, alignRight, alignRightByN, ...rest } = props;
  return (
    <div
      className="-mx-6 -my-2 flex min-w-full flex-col overflow-auto px-6 pt-2 align-middle sm:overflow-visible lg:-mx-8 lg:px-8"
      {...rest}
    >
      <table className="min-w-full border-collapse">
        {alignRight ? (
          <TableHeaderAlignRight columns={columns} />
        ) : alignRightByN ? (
          <TableHeaderAlignRightByN
            columns={columns}
            numberOfColumnsFromLeft={alignRightByN}
          />
        ) : (
          <TableHeaderAlignLeft columns={columns} />
        )}
        <tbody className="border-ash divide-none border-t">{children}</tbody>
      </table>
    </div>
  );
};
