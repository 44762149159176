import { useFormikContext } from "formik";
import get from "lodash.get";

export const ErrorMessage = ({
  keyPath,
}: Readonly<{ keyPath: string }>): JSX.Element => {
  const { errors, touched } = useFormikContext();

  if (
    get(touched, keyPath) !== undefined &&
    get(errors, keyPath) !== undefined
  ) {
    return (
      <div
        className="text-red pb-3 text-xs"
        data-testid={`form::errorMessage::${keyPath}`}
      >
        {get(errors, keyPath)}
      </div>
    );
  }

  return <></>;
};
