import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from "react";
import { DeepReadonly } from "ts-essentials";

export type ConfirmModalValues = {
  title: string;
  description: string;
  buttonText: string;
  isDestructive?: boolean;
};

export type ConfirmModalContextProviderInput = {
  prompt: ConfirmModalValues;
  isOpen: boolean;
  proceed: null | ((value?: any) => void);
  cancel: null | ((value?: any) => void);
};

export interface ConfirmState {
  readonly confirm: ConfirmModalContextProviderInput | null;
  readonly setConfirm: React.Dispatch<
    React.SetStateAction<ConfirmModalContextProviderInput>
  >;
}

export const ConfirmModalContext = createContext<ConfirmState>({
  confirm: null,
  setConfirm: () => {},
});

export const ConfirmModalContextProvider = ({
  children,
}: DeepReadonly<PropsWithChildren<unknown>>): JSX.Element => {
  const [confirm, setConfirm] = useState<ConfirmModalContextProviderInput>({
    prompt: { title: "", description: "", buttonText: "" },
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  const confirmProviderParameters = useMemo(
    () => ({
      confirm: confirm,
      setConfirm: setConfirm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, setConfirm],
  );

  return (
    <ConfirmModalContext.Provider value={confirmProviderParameters}>
      {children}
    </ConfirmModalContext.Provider>
  );
};
