const subDomain = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.replace(
  "/",
  "-",
)
  .replace("@", "")
  .replace(/[./_]+/gu, "");

export const DASHBOARD_URL =
  process.env.NEXT_PUBLIC_DASHBOARD_URL ??
  `https://dashboard-git-${subDomain}.bay1.site`;

export const MARKETING_URL =
  process.env.NEXT_PUBLIC_MARKETING_URL ??
  `https://marketing-git-${subDomain}.bay1.site`;

export const OPS_URL =
  process.env.NEXT_PUBLIC_OPS_URL ?? `https://ops-git-${subDomain}.bay1.site`;

export const isProductionDeployment =
  process.env.NEXT_PUBLIC_DASHBOARD_URL === "https://dashboard.highnote.com";

export const getInitials = (name: string | undefined): string => {
  if (name === "My Company" || name === undefined) {
    return "";
  }

  return name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
};
