import classNames from "classnames";
import { PropsWithChildren } from "react";

export const Dropdown = ({
  children,
  isCollapsed,
  toggleIsCollapsed,
  label,
  opsOnly,
}: Readonly<
  PropsWithChildren<{
    isCollapsed: boolean;
    toggleIsCollapsed: () => void;
    label: string;
    opsOnly?: boolean;
  }>
>) => (
  <div className="text-md group flex cursor-pointer flex-col items-center text-xs duration-200 ease-in">
    <button
      className={classNames("hover:bg-bone flex w-full items-center p-2.5", {
        "bg-bone": !isCollapsed,
      })}
      onClick={toggleIsCollapsed}
      type="button"
    >
      <p>{label}</p>
      {opsOnly && (
        <img alt="" className="ml-2" src="/img/ops-icon-inverse.svg" />
      )}
      <span
        aria-hidden="true"
        className={classNames(
          "pointer-events-none absolute right-2.5 text-black duration-200 ease-in",
          {
            "rotate-90": isCollapsed,
            "-rotate-90": !isCollapsed,
          },
        )}
      >
        <svg
          className="h-4 w-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5l7 7-7 7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
          />
        </svg>
      </span>
    </button>
    {!isCollapsed && (
      <div className="bg-bone flex w-full flex-col pb-2.5 pt-0.5">
        {children}
      </div>
    )}
  </div>
);
