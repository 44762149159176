"use client";

import type { Maybe } from "@bay1/content/generated/graphql";
import { CommonAppContext } from "@bay1/data";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import { Transition } from "@headlessui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import type { ToastResponse } from "./ToastContext";
import { ToastContext } from "./ToastContext";
import ToastSlideOver from "./ToastSlideOver";

// Hooks to use timer with state, https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback: () => void, delay: Maybe<number>) {
  const savedCallback = useRef(callback);
  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== 0) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
    return undefined;
  }, [delay]);
}

export interface ToastQueueEntry {
  response: ToastResponse;
  seen: boolean;
  success: boolean;
  id: string;
}

export const Toast = (): JSX.Element => {
  const { toastState } = useContext(ToastContext);
  const { toastsHistory, toastsToShow, setToastsToShow, setToastsHistory } =
    toastState;
  const [showToastSlideover, setShowToastSlideover] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastResponse, setToastResponse] = useState<ToastQueueEntry>();
  const [toastTitle, setToastTitle] = useState("Success");
  const { activeOrganization } = useContext(CommonAppContext);
  const testEnvironmentDuration =
    activeOrganization?.profile.environment === IntegrationEnvironment.TEST
      ? 2
      : 1;

  const [count, setCount] = useState(0);
  const [initialCount, setInitialCount] = useState<number | undefined>(
    undefined,
  );
  const [pauseToast, setPauseToast] = useState(false);

  const showViewResponseButton =
    activeOrganization?.profile.environment === IntegrationEnvironment.TEST ||
    activeOrganization?.isAdminUserAccessOrg;

  useInterval(
    () => {
      if (
        toastsToShow.length > 0 &&
        !toastsToShow[0].seen &&
        setToastsToShow &&
        initialCount === undefined
      ) {
        const [firstToast] = toastsToShow;
        // eslint-disable-next-line fp/no-mutation
        firstToast.seen = true;
        setToastsToShow([firstToast, ...toastsToShow.slice(1)]);
        setToastTitle(firstToast.success ? "Success" : "Error");
        setToastResponse(firstToast);
        setShowToast(true);
        setInitialCount(count);
      }
      if (
        initialCount !== undefined &&
        setToastsToShow &&
        setToastsHistory &&
        count > initialCount + 3 * testEnvironmentDuration
      ) {
        setToastsToShow(Array.from(toastsToShow.slice(1)));
        toastsHistory?.length > 0
          ? setToastsHistory([...toastsHistory, toastsToShow[0]])
          : setToastsHistory([toastsToShow[0]]);
        setShowToast(false);
        setInitialCount(undefined);
        setCount(0);
      }
      setCount(count + 1);
    },
    pauseToast ? 0 : 1000,
  );

  useEffect(() => {
    if (toastsToShow.length > 0) {
      setPauseToast(false);
    } else {
      setPauseToast(true);
    }
  }, [toastsToShow]);

  const showResponse = useCallback(() => {
    setShowToastSlideover(true);
  }, []);

  const pauseToasts = useCallback(() => {
    setPauseToast(true);
  }, []);

  const resumeToasts = useCallback(() => {
    setPauseToast(false);
  }, []);

  return (
    <>
      <Transition
        className="duration pointer-events-none fixed bottom-10 left-0 z-50 flex w-full rounded-lg"
        enter="transition ease-in-out duration-75 sm:duration-300 lg:translate-x-0"
        enterFrom="translate-y-24"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-75 sm:duration-300"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-24"
        data-testid="toast::notification"
        show={showToast}
      >
        <div
          className="pointer-events-auto mx-auto flex flex-row rounded-lg bg-black text-xs text-white shadow-sm"
          onBlur={resumeToasts}
          onFocus={pauseToasts}
          onMouseLeave={resumeToasts}
          onMouseOver={pauseToasts}
          style={{ width: "fit-content" }}
        >
          {!toastResponse && (
            <>
              <div className="py-4 pl-4">
                <img alt="" src="/img/decline-icon.svg" />
              </div>
              <div className="p-4">{toastTitle}</div>
            </>
          )}
          {toastResponse && (
            <>
              <div className="py-4 pl-4">
                {toastResponse.success ? (
                  <img alt="" src="/img/approved-icon.svg" />
                ) : (
                  <img alt="" src="/img/decline-icon.svg" />
                )}
              </div>
              <div className="p-4">{toastTitle}</div>
              {showViewResponseButton && (
                <button
                  className="cursor-pointer rounded-r-lg border-l border-black/20 px-4 py-4 duration-300 ease-in-out hover:bg-gray-800"
                  onClick={showResponse}
                  type="button"
                  data-testid="toast::showResponse::button"
                >
                  View Response
                </button>
              )}
            </>
          )}
        </div>
      </Transition>
      {toastResponse && (
        <ToastSlideOver
          isOpen={showToastSlideover}
          response={JSON.stringify(toastResponse.response)}
          setIsOpen={setShowToastSlideover}
          title={toastTitle}
        />
      )}
    </>
  );
};
