import type { PropsWithChildren } from "react";
import type { DeepReadonly } from "ts-essentials";

export const FilterPill = ({
  children,
  handleClick,
}: DeepReadonly<
  PropsWithChildren<{
    handleClick: () => void;
  }>
>) => (
  <div className="bg-bone rounded-button my-1 flex items-center p-1 pl-4 text-xs">
    {children}
    <span className="hover:bg-ash rounded-circle ml-2 h-8 w-8 cursor-pointer">
      <button onClick={handleClick} type="button">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="19.4854"
            y="11"
            width="2"
            height="12"
            transform="rotate(45 19.4854 11)"
            fill="black"
          />
          <rect
            x="20.8995"
            y="19.4854"
            width="2"
            height="12"
            transform="rotate(135 20.8995 19.4854)"
            fill="black"
          />
        </svg>
      </button>
    </span>
  </div>
);
