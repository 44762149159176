import Link from "next/link";
import { useContext } from "react";

import { MARKETING_URL } from "../urlHelper";
import { ErrorModalContext } from "./ErrorModalContext";

export const ErrorModal = (): JSX.Element => {
  const { errorModalData, showErrorModal } = useContext(ErrorModalContext);
  return (
    <>
      {showErrorModal && (
        <>
          <div className="bg-ash fixed inset-0 z-40 overflow-y-auto opacity-60" />
          <div
            className="pointer-events-none fixed inset-0 z-50 overflow-y-auto"
            data-testid="modal::errorModal"
          >
            <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:p-0">
              <div
                aria-labelledby="modal-error"
                aria-modal="true"
                className="rounded-highnote pointer-events-auto relative inline-block overflow-hidden bg-white text-left align-bottom antialiased shadow-lg sm:w-full sm:max-w-md sm:align-middle"
                role="dialog"
              >
                <div className="font-display p-6 pb-2 text-lg font-medium">
                  Rate Limit Exceeded
                </div>
                <p className="p-6 pt-2">
                  Usage limit exceeded. See{" "}
                  <Link
                    href={`${MARKETING_URL}/docs/basics/graphql-api/api-rate-limiting`}
                    className="underline"
                    target="_blank"
                  >
                    Rate Limiting Docs
                  </Link>
                  {` for details on how to handle API rate limits.`}
                  <br />
                  <br />
                  {`This page will automatically refresh in 
                  ${errorModalData.retryAfter} seconds.`}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
