"use client";

import { CommonAppContext } from "@bay1/data/CommonAppContext";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import type { PropsWithChildren } from "react";
import React, { useContext } from "react";

export const TestOnly = ({
  children,
  displayForOps,
}: Readonly<PropsWithChildren<{ displayForOps?: boolean }>>): JSX.Element => {
  const { activeOrganization: organization } = useContext(CommonAppContext);

  if (
    organization?.profile.environment === IntegrationEnvironment.TEST &&
    (!organization?.isAdminUserAccessOrg ||
      (organization?.isAdminUserAccessOrg && displayForOps))
  ) {
    return <>{children}</>;
  }

  return <></>;
};
