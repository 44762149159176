import classNames from "classnames";

export const FieldLabel = ({
  label,
  name,
  isSubmitting,
  hasError,
  required,
  labelText,
}: Readonly<{
  label?: JSX.IntrinsicElements["label"];
  labelText: string;
  isSubmitting: boolean;
  hasError: boolean;
  name: string;
  required?: boolean;
}>): JSX.Element => (
  <label
    {...label}
    className={classNames(`${label?.className || ""} text-sm font-medium`, {
      "formLabel cursor-pointer": !isSubmitting,
      "formLabel cursor-not-allowed": isSubmitting,
      "text-black": !hasError,
      "text-red": hasError,
    })}
    data-testid={`form::label::${name}${hasError ? "::error" : ""}`}
    htmlFor={name}
  >
    {labelText}
    {!required && <span className="text-xs text-gray-400"> Optional</span>}
  </label>
);
