import React from "react";

export const LoaderTable = (): JSX.Element => (
  <div className="flex flex-col">
    <div className="-mx-4 -my-2 overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="animate-pulse overflow-hidden rounded">
          <table className="min-w-full">
            <thead className="bg-white">
              <tr>
                <th className="px-6 py-3" scope="col">
                  <div className="bg-bone h-4 w-36 rounded" />
                </th>
                <th className="px-6 py-3" scope="col">
                  <div className="bg-bone h-4 w-36 rounded" />
                </th>
                <th className="relative px-6 py-3" scope="col">
                  <div className="bg-bone h-4 w-36 rounded" />
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="bg-white">
                <td className="flex items-center space-x-3 px-6 py-4">
                  <div className="bg-bone h-10 w-10 rounded" />
                  <div className="bg-bone h-4 w-48 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-60 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-24 rounded" />
                </td>
              </tr>
              <tr className="bg-white">
                <td className="flex items-center space-x-3 px-6 py-4">
                  <div className="bg-bone h-10 w-10 rounded" />
                  <div className="bg-bone h-4 w-48 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-36 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-24 rounded" />
                </td>
              </tr>
              <tr className="bg-white">
                <td className="flex items-center space-x-3 px-6 py-4">
                  <div className="bg-bone h-10 w-10 rounded" />
                  <div className="bg-bone h-4 w-48 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-52 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-24 rounded" />
                </td>
              </tr>
              <tr className="bg-white">
                <td className="flex items-center space-x-3 px-6 py-4">
                  <div className="bg-bone h-10 w-10 rounded" />
                  <div className="bg-bone h-4 w-48 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-36 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-24 rounded" />
                </td>
              </tr>
              <tr className="bg-white">
                <td className="flex items-center space-x-3 px-6 py-4">
                  <div className="bg-bone h-10 w-10 rounded" />
                  <div className="bg-bone h-4 w-48 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-56 rounded" />
                </td>
                <td className="px-6 py-4">
                  <div className="bg-bone h-4 w-24 rounded" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);
